import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_getting_started_faq_toll_free = _resolveComponent("getting-started-faq-toll-free")!
  const _component_getting_started_faq_dlc = _resolveComponent("getting-started-faq-dlc")!
  const _component_getting_started_faq_byoc = _resolveComponent("getting-started-faq-byoc")!
  const _component_faq_block = _resolveComponent("faq-block")!

  return (_openBlock(), _createBlock(_component_faq_block, {
    title: _ctx.title,
    text: "Haven’t found what you were looking for?",
    tabs: _ctx.tabs
  }, {
    "panel-toll-free": _withCtx(() => [
      _createVNode(_component_getting_started_faq_toll_free)
    ]),
    "panel-dlc": _withCtx(() => [
      _createVNode(_component_getting_started_faq_dlc)
    ]),
    "panel-byoc": _withCtx(() => [
      _createVNode(_component_getting_started_faq_byoc)
    ]),
    _: 1
  }, 8, ["title", "tabs"]))
}