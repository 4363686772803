
import { defineComponent } from 'vue'
import GettingStartedFaqTollFree from '@/components/pages/gettingStarted/GettingStartedFaqTollFree.vue'
import GettingStartedFaqDlc from '@/components/pages/gettingStarted/GettingStartedFaqDlc.vue'
import GettingStartedFaqByoc from '@/components/pages/gettingStarted/GettingStartedFaqByoc.vue'
import FaqBlock from '@/components/shared/templates/FaqBlock.vue'

const tabs = [
  {
    name: 'toll-free',
    label: 'Toll-free numbers',
  },
  {
    name: 'dlc',
    label: '10DLC numbers',
  },
  {
    name: 'byoc',
    label: 'Bring your own CPaaS (BYOC)',
  },
]

export default defineComponent({
  components: {
    FaqBlock,
    GettingStartedFaqTollFree,
    GettingStartedFaqDlc,
    GettingStartedFaqByoc,
  },
  props: {
    title: {
      type: String,
      default: 'Getting started FAQ',
    },
  },
  setup() {
    return {
      tabs,
    }
  },
})
